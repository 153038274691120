<template>
  <div>
    <!-- Table Container Card -->
    <modal-course :yes-no-options="yesNoOptions" :user-options="userOptions" :organization-options="organizationOptions"
      :license-authority-options="licenseAuthorityOptions" @refetch-data="refetchData" />
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <div class="m-2">
          <!-- Table Top -->

          <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
            <h3 class="font-weight-bolder text-nowrap mb-0">Course Record</h3>
            <div class="form-col-select my-sm-0 ml-sm-2">
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <feather-icon size="18" icon="ChevronDownIcon" />
            </div>
            <div class="search-primary w-100">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Find Course" />
            </div>
            
            <b-dropdown 
              class="mobile-w100 mr-1" 
              variant="primary-green"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            >
              <template #button-content>
                <span class="text-nowrap" style="color:white;">
                  <feather-icon size="14" icon="FileTextIcon" />
                  Export
                </span>
              </template>
              
              <b-dropdown-item
                v-for="type in reportTypes"
                :key="type.id"
                @click="exportExcel(type.id)"
              >
                <span>{{ type.name }}</span>
                <small class="d-block text-muted">{{ type.description }}</small>
              </b-dropdown-item>
            </b-dropdown>

            <b-button class="mobile-w100" v-if="ability.can('create', 'course')"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="handleAddCourse" variant="primary">
              <span class="text-nowrap"><feather-icon size="14" icon="PlusIcon" /> Record</span>
            </b-button>
          </div>

          <div class="d-flex flex-wrap mt-2 mt-sm-1 flex-sm-nowrap">
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = null">
              <feather-icon icon="AlignJustifyIcon" class="mr-50" />
              All
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '1'">
              <feather-icon icon="BookOpenIcon" class="mr-50" />
              Approved
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '0'">
              <feather-icon icon="LoaderIcon" class="mr-50" />
              Pending Approval
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '-1'">
              <feather-icon icon="CheckIcon" class="mr-50" />
              Rejected
            </b-button>
            <div class="d-flex justify-content-between align-items-center">
              <div class="mr-2 mt-1 mt-sm-0">
                <!-- <label for="filter-date-from" class="mr-2">From:</label> -->
                <flat-pickr id="filter-date-from" v-model="dateFrom" :config="flatDateConfig" class="form-control"
                  placeholder="Date From" />
              </div>
              <div class="mr-2 mt-1 mt-sm-0">-</div>
              <div>
                <!-- <label for="filter-date-to" class="ml-2 mr-2">To:</label> -->
                <flat-pickr id="filter-date-to" v-model="dateTo" :config="flatDateConfig" class="form-control"
                  placeholder="Date To" />
              </div>
            </div>
          </div>
        </div>

        <b-table ref="refCourseListTable" class="position-relative" :items="getAllCourseRecord" responsive
          :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
          empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
          <template #cell(ethics_compliance)="data">
            <div v-if="data.item.ethics_compliance == 0">No</div>
            <div v-else>Yes</div>
          </template>

          <template #cell(course_organization_name)="data">
            <div v-if="data.item.course_organization_name == 'Others'">{{ data.item.course_organization_name + " - " +
              data.item.organization_name }} </div>
            <div v-else>{{ data.item.course_organization_name }}</div>
          </template>

          <template #cell(relevant_topic)="data">
            <div v-if="data.item.relevant_topic == 0">No</div>
            <div v-else-if="data.item.relevant_topic == 1">Yes</div>
            <div v-else>N/A</div>
          </template>

          <template #cell(license_authority_name)="data">
            <div v-if="data.item.both_bit == 1">SFC & IA</div>
            <div v-else>
              {{ data.item.license_authority_name }}
            </div>
          </template>

          <template #cell(total_approved)="data">
            <div v-if="data.item.status != null">
              <span>
                {{
                  data.item.staff_records.filter((record) => record.approved_bit == 1).length
                }}
                / {{ data.item.staff_records.length }}
              </span>
            </div>
            <div v-else>

              <div v-if="data.item.both_bit === 1" class="d-flex">
                <div v-for="(record, index) in data.item.staff_records" :key="index">
                  <div v-if="record.approved_bit == 0" size="18" class="mr-50 text-secondary">
                    {{ record.authority_id == 1 ? 'SFC' : 'IA' }} Pending Approval
                  </div>
                  <div v-if="record.approved_bit == 1" size="18" class="mr-50 text-success">
                    {{ record.authority_id == 1 ? 'SFC' : 'IA' }} &#10003;
                  </div>
                  <div v-if="record.approved_bit == -1" size="18" class="mr-50 text-danger">
                    {{ record.authority_id == 1 ? 'SFC' : 'IA' }} &#x2717;
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="data.item.staff_records[0].approved_bit == 0" size="18" class="mr-50 text-secondary">Pending
                  Approval
                </div>
                <div v-if="data.item.staff_records[0].approved_bit == 1" size="18" class="mr-50 text-success">&#10003
                </div>
                <div v-if="data.item.staff_records[0].approved_bit == -1" size="18" class="mr-50 text-danger">&#x2717
                </div>
              </div>
            </div>
          </template>

          <!-- <template #cell(total_approved)="data">
            <span v-if="data.item.approved_bit == '0' || data.item.approved_bit == '1'">
              <div v-if="data.item.both_bit === 1" class="d-flex">
                <div v-if="data.item.approved_bit == 0" size="18" class="mr-50 text-secondary">{{
                  data.item.license_authority_name }} Pending Approval
                </div>
                <div v-if="data.item.approved_bit == 1" size="18" class="mr-50 text-success">{{
                  data.item.license_authority_name }} &#10003</div>
                <div v-if="data.item.approved_bit == -1" size="18" class="mr-50 text-danger">{{
                  data.item.license_authority_name }} &#x2717</div>
              </div>
              <div v-else>
                <div v-if="data.item.approved_bit == 0" size="18" class="mr-50 text-secondary">Pending Approval
                </div>
                <div v-if="data.item.approved_bit == 1" size="18" class="mr-50 text-success">&#10003</div>
                <div v-if="data.item.approved_bit == -1" size="18" class="mr-50 text-danger">&#x2717</div>
              </div>
            </span>
            <span v-else-if="data.item.staff_records && !data.item.approved_bit">
              {{
                data.item.staff_records.filter((record) => record.approved_bit == 1).length
              }}
              / {{ data.item.staff_records.length }}
            </span>
          </template> -->

          <!-- Column: Actions -->
          <template v-if="ability.can('update', 'course')" #cell(actions)="data">
            <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item v-if="ability.can('update', 'course')" :to="{
                name: 'users-training-record-edit',
                params: { id: data.item.id },
              }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="ability.can('delete', 'course')" @click="handleDeleteCourse(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalCourses" :per-page="perPage" first-number
                last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useCourseList from "./useCourseList";
import userStoreModule from "../userStoreModule";
import ModalCourse from "../modal/ModalCourse.vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "animate.css";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    ModalCourse,
    vSelect,
    flatPickr,
  },
  methods: {
    handleDeleteCourse(id) {
      this.$swal({
        title: "Are you sure?",
        text:
          "You will not be able to retrieve the course record, and all related staff records will be deleted.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('app-user/deleteCourse', { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleAddCourse() {
      this.$bvModal.show("modal-course");
    },
    exportExcel(reportType) {
      this.loading = true;

      let data = {
        date_from: this.dateFrom,
        date_to: this.dateTo,
        report_type: reportType
      }

      store
        .dispatch('app-user/exportCourseRecord', data)
        .then((response) => {
          this.loading = false;
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          const fileName = `${this.dateFrom} - ${this.dateTo}.xlsx`;
          link.download = fileName;
          link.href = href;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
        })
    },
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const loading = ref(false);

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    onMounted(() => {
      fetchOptions();
    });

    const isAddNewUserSidebarActive = ref(false);

    const yesNoOptions = [
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 0,
        name: "No",
      },
    ];

    const userOptions = ref([]);
    const licenseAuthorityOptions = ref([]);
    const organizationOptions = ref([]);
    const reportTypes = ref([])

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", {
          license_authority: true,
          user: true,
          course_organization: true,
          report_types:true
        })
        .then((response) => {
          console.log(response);
          reportTypes.value = response.data.report_types;
          licenseAuthorityOptions.value = response.data.license_authorities;
          licenseAuthorityOptions.value.unshift({
            id: "b",
            authority_name: "SFC & IA",
            training_name: "Both",
          });
          organizationOptions.value = response.data.course_organizations;
          userOptions.value = response.data.users;
          userOptions.value.unshift({ id: 0, name: "All" });
        })
        .catch((error) => {
          // if (error.response.status === 404) {
          licenseAuthorityOptions.value = undefined;
          organizationOptions.value = undefined;
          userOptions.value = undefined;
          console.log(error);
          // }
        });
    };

    const {
      getAllCourseRecord,
      tableColumns,
      perPage,
      currentPage,
      totalCourses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      courseRecords,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      dateFrom,
      dateTo,
      ability,
    } = useCourseList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      loading,

      getAllCourseRecord,
      tableColumns,
      perPage,
      currentPage,
      totalCourses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      ability,
      courseRecords,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      dateFrom,
      dateTo,
      yesNoOptions,
      userOptions,
      licenseAuthorityOptions,
      organizationOptions,
      reportTypes
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}
</style>
